import React from 'react'

export default function Footer() {
  return (
    <>
      <footer className="relative bg-gray-300 pt-8 pb-6 bg-gray-900">
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
          style={{ height: '80px', transform: 'translateZ(0)' }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-gray-300 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap">
            <div className="w-full lg:w-6/12 px-4">
              <div className="flex items-center">
                <img
                  className="h-8"
                  src={require('assets/img/logo.png')}
                  alt="logo"
                />
                <h4 className="text-2xl ml-4 text-gray-300 font-semibold">
                  POD Personalizer
                </h4>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="flex flex-wrap items-top mb-6">
                <div className="w-full lg:w-4/12 px-4 ml-auto">
                  <span className="block uppercase text-gray-400 text-sm font-semibold mb-2">
                    Useful Links
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="text-gray-500 hover:text-gray-300 font-semibold block pb-2 text-sm"
                        href="/"
                      >
                        Home
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-gray-500 hover:text-gray-300 font-semibold block pb-2 text-sm"
                        href="/"
                      >
                        Features
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-gray-500 hover:text-gray-300 font-semibold block pb-2 text-sm"
                        href="/"
                      >
                        Pricing
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <span className="block uppercase text-gray-400 text-sm font-semibold mb-2">
                    Other Resources
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="text-gray-500 hover:text-gray-300 font-semibold block pb-2 text-sm"
                        href="/faq"
                      >
                        FAQ's
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-gray-500 hover:text-gray-300 font-semibold block pb-2 text-sm"
                        href="/privacy-policy"
                      >
                        Privacy Policy
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-6 border-gray-400" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm text-gray-600 font-semibold py-1">
                Copyright © {new Date().getFullYear()} POD Personalizer by{' '}
                <a
                  href="https://pod.lotusbod.com/"
                  className="text-gray-500 hover:text-gray-300"
                >
                  Chill Group
                </a>
                .
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}
