import React from 'react'

import Navbar from 'components/Navbar.js'
import Footer from 'components/Footer.js'

export default function FAQ() {
  return (
    <>
      <Navbar transparent />
      <main className="profile-page">
        <section className="relative block" style={{ height: '500px' }}>
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                "url('https://images.unsplash.com/photo-1499336315816-097655dcfbda?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2710&q=80')",
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            ></span>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: '70px', transform: 'translateZ(0)' }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-300 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </section>
        <section className="relative py-16 bg-gray-300">
          <div className="container mx-auto px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full shadow-xl rounded-lg -mt-64">
              <div className="flex flex-wrap justify-center mr-2">
                <div className="w-full lg:w-9/12">
                  <p className="mt-4 text-xl leading-relaxed font-bold text-gray-900">
                    FAQ's
                  </p>
                </div>
              </div>
              <div className="mt-4 py-6 border-t border-gray-300 text-start">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-9/12">
                    <p className="mb-1 text-lg leading-relaxed font-semibold text-gray-900">
                      Guide how to use app?
                    </p>
                    <div>
                      <div className="my-4  font-semibold">General: </div>
                      <div className="mt-2 ml-4">
                        - Each template is distinguished by its name and
                        description, and each template will contain different
                        configs.
                      </div>
                      <div className="mt-2 ml-4">
                        - Each product obtained from the shop can use the
                        configs of
                        <strong> only one</strong> template.
                      </div>
                      <div className="my-4 font-semibold">Start screen:</div>
                      <div className="border border-gray-600 p-2 mx-2 my-4">
                        <img
                          src="https://i.imgur.com/i4qxsbo.png"
                          alt="start-screen"
                        />
                      </div>
                      {/* List Template */}
                      <div className="my-4 font-semibold">1. List Product</div>
                      {/* Feature 1 */}
                      <div>
                        <strong>Feature 1</strong>: Search Product by Title
                      </div>
                      <div className="border border-gray-600 p-2 mx-2 my-4">
                        <img
                          src="https://i.imgur.com/vKzJ8kA.png"
                          alt="search-product"
                        />
                      </div>
                      {/* Feature 2 */}
                      <div>
                        <strong>Feature 2</strong>: Filter Product by{' '}
                        <strong>List Product </strong>(All products includes
                        configured and non-configured Products) or{' '}
                        <strong>List Configured Product</strong> (only
                        configured Products)
                      </div>
                      <div className="border border-gray-600 p-2 mx-2 my-4">
                        <img
                          src="https://i.imgur.com/VKTN0Vx.png"
                          alt="search-product"
                        />
                      </div>
                      {/* Feature 3 */}
                      <div>
                        <strong>Feature 3</strong>: Click to title or icon to
                        view product
                      </div>
                      <div className="border border-gray-600 p-2 mx-2 my-4">
                        <img
                          src="https://i.imgur.com/kFupRur.png"
                          alt="search-product"
                        />
                      </div>
                      {/* Feature 4 */}
                      <div>
                        <strong>Feature 4</strong>: Apply Template
                      </div>
                      <div className="mt-4">
                        Click to <strong>Apply Template</strong> button {'->'}{' '}
                        Search and click button <strong>Choose</strong> {'->'}{' '}
                        Popup <strong>Choose Template successful!</strong>
                      </div>
                      <div>
                        <div className="border border-gray-600 p-2 mx-2 my-4">
                          <img
                            src="https://i.imgur.com/aCFPlqO.png"
                            alt="apply-template"
                          />
                        </div>
                        <div className="border border-gray-600 p-2 mx-2 my-4 flex items-center justify-center">
                          <img
                            src="https://i.imgur.com/DO3OKxs.png"
                            alt="choose-template"
                          />
                        </div>
                        <div className="border border-gray-600 p-2 mx-2 my-4 flex items-center justify-center">
                          <img
                            src="https://i.imgur.com/YDoub0Y.png"
                            alt="apply-template-success"
                          />
                        </div>
                      </div>
                      {/* Feature 5 */}
                      <div>
                        <strong>Feature 5</strong>: Change Template
                      </div>
                      <div>
                        <div>
                          <div className="border border-gray-600 p-2 mx-2 my-4 flex items-center justify-center">
                            <img
                              src="https://i.imgur.com/qMghgma.png"
                              alt="change-template"
                            />
                          </div>
                          <div className="border border-gray-600 p-2 mx-2 my-4 flex items-center justify-center">
                            <img
                              src="https://i.imgur.com/OFPKcGa.png"
                              alt="change-template"
                            />
                          </div>
                        </div>
                        <div className="border border-gray-600 p-2 mx-2 my-4 flex items-center justify-center">
                          <img
                            src="https://i.imgur.com/DO3OKxs.png"
                            alt="choose-template"
                          />
                        </div>
                        <div className="border border-gray-600 p-2 mx-2 my-4 flex items-center justify-center">
                          <img
                            src="https://i.imgur.com/iKrzpyJ.png"
                            alt="change-template-success"
                          />
                        </div>
                      </div>
                      {/* Feature 6 */}
                      <div>
                        <strong>Feature 6</strong>: Delete Template
                        <div>
                          <div className="border border-gray-600 p-2 mx-2 my-4 flex items-center justify-center">
                            <img
                              src="https://i.imgur.com/balgFHJ.png"
                              alt="delete-template"
                            />
                          </div>
                          <div className="border border-gray-600 p-2 mx-2 my-4 flex items-center justify-center">
                            <img
                              src="https://i.imgur.com/PB7ITn4.png"
                              alt="delete-template-success"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="my-4 ">2. Manage Template</div>
                      {/* Feature 1 */}
                      <div>
                        <strong>Feature 1</strong>: Create Template
                        <div>
                          <div className="border border-gray-600 p-2 mx-2 my-4 flex items-center justify-center">
                            <img
                              src="https://i.imgur.com/GsIpe63.png"
                              alt="create-template"
                            />
                          </div>
                          <div className="border border-gray-600 p-2 mx-2 my-4 flex items-center justify-center">
                            <img
                              src="https://i.imgur.com/8DGI6hU.png"
                              alt="create-template-form"
                            />
                          </div>
                        </div>
                      </div>
                      {/* Feature 2 */}
                      <div>
                        <strong>Feature 2</strong>: Create Config
                        <div>
                          <div className="border border-gray-600 p-2 mx-2 my-4 flex items-center justify-center">
                            <img
                              src="https://i.imgur.com/JcnYJx9.png"
                              alt="create-template"
                            />
                          </div>
                          <div className="border border-gray-600 p-2 mx-2 my-4 flex items-center justify-center">
                            <img
                              src="https://i.imgur.com/mOjQN09.png"
                              alt="create-template-form"
                            />
                          </div>
                          <div className="border border-gray-600 p-2 mx-2 my-4 flex items-center justify-center">
                            <img
                              src="https://i.imgur.com/ra6NpRf.png"
                              alt="create-template-success"
                            />
                          </div>
                        </div>
                      </div>
                      {/* Feature 3 */}
                      <>
                        <strong>Feature 3</strong>: Edit Template
                        <div>
                          <div className="border border-gray-600 p-2 mx-2 my-4 flex items-center justify-center">
                            <img
                              src="https://i.imgur.com/kVgXW2n.png"
                              alt="edit-template"
                            />
                          </div>
                          <div className="border border-gray-600 p-2 mx-2 my-4 flex items-center justify-center">
                            <img
                              src="https://i.imgur.com/4sV4zQL.png"
                              alt="edit-template"
                            />
                          </div>
                          <div className="border border-gray-600 p-2 mx-2 my-4 flex items-center justify-center">
                            <img
                              src="https://i.imgur.com/LJpnFsZ.png"
                              alt="save-template-success"
                            />
                          </div>
                        </div>
                      </>
                      {/* Feature 4 */}
                      <div>
                        <strong>Feature 4</strong>: Edit Config
                        <div>
                          <div className="border border-gray-600 p-2 mx-2 my-4 flex items-center justify-center">
                            <img
                              src="https://i.imgur.com/g8VZIQt.png"
                              alt="edit-template"
                            />
                          </div>
                          <div className="border border-gray-600 p-2 mx-2 my-4 flex items-center justify-center">
                            <img
                              src="https://i.imgur.com/mOjQN09.png"
                              alt="edit-template"
                            />
                          </div>
                          <div className="border border-gray-600 p-2 mx-2 my-4 flex items-center justify-center">
                            <img
                              src="https://i.imgur.com/AurqJyw.png"
                              alt="save-template-success"
                            />
                          </div>
                        </div>
                      </div>
                      {/* Feature 5 */}
                      <div>
                        <strong>Feature 5</strong>: Apply template to collection
                        <div>
                          <div className="border border-gray-600 p-2 mx-2 my-4 flex items-center justify-center">
                            <img
                              src="https://i.imgur.com/kVgXW2n.png"
                              alt="edit-template"
                            />
                          </div>
                          <div className="border border-gray-600 p-2 mx-2 my-4 flex items-center justify-center">
                            <img
                              src="https://i.imgur.com/bCw2Hrn.png"
                              alt="edit-template"
                            />
                          </div>
                          <div className="border border-gray-600 p-2 mx-2 my-4 flex items-center justify-center">
                            <img
                              src="https://i.imgur.com/0wj392b.png"
                              alt="save-template-success"
                            />
                          </div>
                        </div>
                      </div>
                      {/* Feature 6 */}
                      <div>
                        <strong>Feature 6</strong>: Apply template to list
                        product
                        <div>
                          <div className="border border-gray-600 p-2 mx-2 my-4 flex items-center justify-center">
                            <img
                              src="https://i.imgur.com/kVgXW2n.png"
                              alt="edit-template"
                            />
                          </div>
                          <div className="border border-gray-600 p-2 mx-2 my-4 flex items-center justify-center">
                            <img
                              src="https://i.imgur.com/YIC8LLL.png"
                              alt="edit-template"
                            />
                          </div>
                          <div className="border border-gray-600 p-2 mx-2 my-4 flex items-center justify-center">
                            <img
                              src="https://i.imgur.com/6Epf2UA.png"
                              alt="save-template-success"
                            />
                          </div>
                        </div>
                      </div>
                      {/* Feature 7 */}
                      <div>
                        <strong>Feature 7</strong>: Delete Template
                        <div>
                          <div className="border border-gray-600 p-2 mx-2 my-4 flex items-center justify-center">
                            <img
                              src="https://i.imgur.com/lORBGxH.png"
                              alt="delete-template"
                            />
                          </div>
                          <div className="border border-gray-600 p-2 mx-2 my-4 flex items-center justify-center">
                            <img
                              src="https://i.imgur.com/PB7ITn4.png"
                              alt="delete-template-success"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}
