import React from 'react'

import Navbar from 'components/Navbar.js'
import Footer from 'components/Footer.js'

export default function PrivacyPolicy() {
  return (
    <>
      <Navbar transparent />
      <main className="profile-page">
        <section className="relative block" style={{ height: '500px' }}>
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                "url('https://images.unsplash.com/photo-1499336315816-097655dcfbda?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2710&q=80')",
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            ></span>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: '70px', transform: 'translateZ(0)' }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-300 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </section>
        <section className="relative py-16 bg-gray-300">
          <div className="container mx-auto px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
              <div className="flex flex-wrap justify-center">
                <div className="w-full lg:w-9/12">
                  <p className="mt-4 text-xl leading-relaxed font-bold text-gray-900">
                    Privacy Policy
                  </p>
                </div>
              </div>
              <div className="mt-4 py-6 border-t border-gray-300 text-start">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-9/12">
                    <p className="mb-4 text-md leading-relaxed text-gray-800">
                      At POD Personalizer, accessible from{' '}
                      <a
                        href="https://pod.lotusbod.com"
                        className="text-blue-500"
                      >
                        https://pod.lotusbod.com
                      </a>
                      , one of our main priorities is the privacy of our
                      visitors. This Privacy Policy document contains types of
                      information that is collected and recorded by POD
                      Personalizer and how we use it.
                    </p>

                    <p className="mb-4 text-md leading-relaxed text-gray-800">
                      If you have additional questions or require more
                      information about our Privacy Policy, do not hesitate to
                      contact us.
                    </p>

                    <p className="mb-1 text-lg leading-relaxed font-semibold text-gray-900">
                      Log Files
                    </p>

                    <p className="mb-4 text-md leading-relaxed text-gray-800">
                      POD Personalizer follows a standard procedure of using log
                      files. These files log visitors when they visit websites.
                      All hosting companies do this and a part of hosting
                      services' analytics. The information collected by log
                      files include internet protocol (IP) addresses, browser
                      type, Internet Service Provider (ISP), date and time
                      stamp, referring/exit pages, and possibly the number of
                      clicks. These are not linked to any information that is
                      personally identifiable. The purpose of the information is
                      for analyzing trends, administering the site, tracking
                      users' movement on the website, and gathering demographic
                      information.
                    </p>

                    <p className="mb-1 text-lg leading-relaxed font-semibold text-gray-900">
                      Cookies and Web Beacons
                    </p>

                    <p className="mb-4 text-md leading-relaxed text-gray-800">
                      Like any other website, POD Personalizer uses 'cookies'.
                      These cookies are used to store information including
                      visitors' preferences, and the pages on the website that
                      the visitor accessed or visited. The information is used
                      to optimize the users' experience by customizing our web
                      page content based on visitors' browser type and/or other
                      information.
                    </p>

                    <p className="mb-4 text-md leading-relaxed text-gray-800">
                      For more general information on cookies, please read{' '}
                      <a href="https://www.cookieconsent.com/what-are-cookies/">
                        "What Are Cookies"
                      </a>
                      .
                    </p>

                    <p className="mb-4 text-md leading-relaxed text-gray-800">
                      You can choose to disable cookies through your individual
                      browser options. To know more detailed information about
                      cookie management with specific web browsers, it can be
                      found at the browsers' respective websites. What Are
                      Cookies?
                    </p>

                    <p className="mb-1 text-lg leading-relaxed font-semibold text-gray-900">
                      Online Privacy Policy Only
                    </p>

                    <p className="mb-4 text-md leading-relaxed text-gray-800">
                      This Privacy Policy applies only to our online activities
                      and is valid for visitors to our website with regards to
                      the information that they shared and/or collect in POD
                      Personalizer. This policy is not applicable to any
                      information collected offline or via channels other than
                      this website.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}
