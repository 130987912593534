import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import '@fortawesome/fontawesome-free/css/all.min.css'

import Landing from 'views/Landing.js'
import Faq from 'views/Faq.js'
import PrivacyPolicy from 'views/PrivacyPolicy.js'

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Landing} />
      <Route path="/faq" component={Faq} />
      <Route path="/privacy-policy" component={PrivacyPolicy} />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
)
