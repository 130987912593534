import React from 'react'

import Navbar from 'components/Navbar.js'
import Footer from 'components/Footer.js'

export default function Landing() {
  const [link, setLink] = React.useState('')

  const setLinkText = (event) => setLink(event.target.value)
  const redirectShop = () => {
    window.location.href = `https://productpersonalizer.godmerch.com/auth/inline?shop=${link}`
  }
  return (
    <>
      <Navbar transparent />
      <main>
        <div
          className="relative pt-16 pb-32 flex content-center items-center justify-center"
          style={{
            minHeight: '75vh',
          }}
        >
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                "url('https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80')",
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-75 bg-black"
            ></span>
          </div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="pr-12">
                  <h1 className="text-white font-semibold text-5xl">
                    Unlimited Customization
                  </h1>
                  <p className="mt-4 text-lg text-gray-300">
                    Reduce delivery time by automating the customer preference
                    right from your store.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: '70px', transform: 'translateZ(0)' }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-300 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>

        <section className="pb-20 bg-gray-300 -mt-24">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                      <i className="fas fa-award"></i>
                    </div>
                    <h6 className="text-xl font-semibold">
                      Product Option Input Types
                    </h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      Customer can create two customize option input types:
                      single-lines and multi-lines.
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                      <i className="fas fa-retweet"></i>
                    </div>
                    <h6 className="text-xl font-semibold">
                      Best Customer Experience
                    </h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      Good UI experience and easy to use
                    </p>
                  </div>
                </div>
              </div>

              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400">
                      <i className="fas fa-fingerprint"></i>
                    </div>
                    <h6 className="text-xl font-semibold">
                      Unlimited Customizations options
                    </h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      With Unlimited Customizations options - easy to use
                      interface to build your products.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-20 bg-gray-300 -mt-24">
          <div className="container mx-auto mt-16">
            <div className="w-full flex items-center">
              <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 shadow-lg rounded-full bg-gray-100">
                <i className="fas fa-money-bill text-xl"></i>
              </div>
              <h3 className="text-3xl ml-4 font-semibold leading-normal">
                Pricing
              </h3>
            </div>
          </div>
          <div className="container mx-auto">
            <div className="flex md:flex-row flex-col">
              <div className="lg:pt-12 pt-6 w-full md:w-1/6 px-2 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="py-5">
                    <h6 className="text-2xl font-semibold">FREE</h6>
                    <img
                      className="mx-auto w-16 h-16 my-4"
                      src="https://img.icons8.com/nolan/2x/one-free.png"
                      alt="free"
                    />
                    <p className="mt-2 mb-2 text-gray-600">
                      <strong className="text-orange-500 text-xl">0$</strong>{' '}
                      <span className="text-gray-900 font-semibold">
                        / month
                      </span>
                    </p>
                    <hr />
                    <p className="my-4 text-gray-900">Max product: 100</p>
                  </div>
                </div>
              </div>

              <div className="lg:pt-12 pt-6 w-full md:w-1/6 px-2 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="py-5">
                    <h6 className="text-2xl font-semibold">BASIC</h6>
                    <img
                      className="mx-auto w-16 h-16 my-4"
                      src="https://img.icons8.com/nolan/2x/package.png"
                      alt="free"
                    />
                    <p className="mt-2 mb-2 text-gray-600">
                      <strong className="text-orange-500 text-xl">9.99$</strong>{' '}
                      <span className="text-gray-900 font-semibold">
                        / month
                      </span>
                    </p>
                    <hr />
                    <p className="my-4 text-gray-900">Max product: 200</p>
                  </div>
                </div>
              </div>

              <div className="lg:pt-12 pt-6 w-full md:w-1/6 px-2 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="py-5">
                    <h6 className="text-2xl font-semibold">GOLD</h6>
                    <img
                      className="mx-auto w-16 h-16 my-4"
                      src="https://img.icons8.com/nolan/2x/gold-bars.png"
                      alt="free"
                    />
                    <p className="mt-2 mb-2 text-gray-600">
                      <strong className="text-orange-500 text-xl">
                        13.99$
                      </strong>{' '}
                      <span className="text-gray-900 font-semibold">
                        / month
                      </span>
                    </p>
                    <hr />
                    <p className="my-4 text-gray-900">Max product: 300</p>
                  </div>
                </div>
              </div>

              <div className="lg:pt-12 pt-6 w-full md:w-1/6 px-2 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="py-5">
                    <h6 className="text-2xl font-semibold">PREMIUM</h6>
                    <img
                      className="mx-auto w-16 h-16 my-4"
                      src="https://img.icons8.com/nolan/2x/best-seller.png"
                      alt="free"
                    />
                    <p className="mt-2 mb-2 text-gray-600">
                      <strong className="text-orange-500 text-xl">
                        19.99$
                      </strong>{' '}
                      <span className="text-gray-900 font-semibold">
                        / month
                      </span>
                    </p>
                    <hr />
                    <p className="my-4 text-gray-900">Max product: 400</p>
                  </div>
                </div>
              </div>

              <div className="lg:pt-12 pt-6 w-full md:w-1/6 px-2 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="py-5">
                    <h6 className="text-2xl font-semibold">UNLIMITED</h6>
                    <img
                      className="mx-auto w-16 h-16 my-4"
                      src="https://img.icons8.com/nolan/2x/power-plant.png"
                      alt="free"
                    />
                    <p className="mt-2 mb-2 text-gray-600">
                      <strong className="text-orange-500 text-xl">
                        29.99$
                      </strong>{' '}
                      <span className="text-gray-900 font-semibold">
                        / month
                      </span>
                    </p>
                    <hr />
                    <p className="my-4 text-gray-900">Unlimited product</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-20 bg-gray-300 -mt-24">
          <div className="container mx-auto mt-16">
            <div className="w-full flex items-center">
              <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 shadow-lg rounded-full bg-gray-100">
                <i className="fa fa-cog text-xl"></i>
              </div>
              <h3 className="text-3xl ml-4 font-semibold leading-normal">
                Setup
              </h3>
            </div>
          </div>
          <div className="container mx-auto">
            <div className="flex md:flex-row flex-col w-full">
              <div className="flex items-center w-full justify-center whitespace-no-wrap mt-8">
                <input
                  type="text"
                  className="px-3 py-3 placeholder-gray-400 w-50 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-6/12"
                  placeholder="Shop name"
                  onChange={setLinkText}
                  style={{ transition: 'all .15s ease' }}
                />
                <button
                  onClick={redirectShop}
                  class="mx-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Install now
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  )
}
